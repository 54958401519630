import React from "react";
import Icon from "./location.png";
export default function VendorAccountDetails({ userAccountDet }) {
  return (
    <div style={{ marginTop: "25px" }}>
      <div>
        <div className="userAccountDetails">
          <div className="col1a">
            <span>Bank</span>
            <span>{userAccountDet?.bankName}</span>
          </div>

          <div className="col1a">
            <span>Account Details</span>
            <span>{`${userAccountDet?.accountNumber}, ${userAccountDet?.accountName}`}</span>
          </div>

          <div className="col1a">
            <span>Account Status</span>
            <span>{`${userAccountDet.status}`}</span>
          </div>
        </div>
        <div className="hrProfilePage"></div>
      </div>
    </div>
  );
}

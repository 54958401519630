import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout";
import { AgentTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet } from "../../../action/http";

const Index = (props) => {
  const [agents, setagents] = useState([]);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getagents();
  }, []);

  const getagents = async () => {
    try {
      const res = await httpGet("/all_delivery_agents");
      if (res.code === 200) {
        setagents(res.data.rider);
        setLoading(false);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="agent_mgt"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          {/* <div>
            <button
              className="create-ntn654ty"
              onClick={() => props.history.push("/create_agent")}
            >
              Create New Agent
            </button>
          </div> */}

          <AgentTable
            getagents={agents || []}
            GetEditDataModals={GetEditDataModals}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getagents={getagents}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;

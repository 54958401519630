import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import Table from '../../shared/CustomTable';
import currencyFormatter from 'currency-formatter';
import FormatDate from '../../../helpers/formatDate';
export const TransactionTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getTransactions.map((data, index) => ({
      reference: (
        <Link to={`/view_transaction/${data.id}`}>{data.reference}</Link>
      ),
      amount: currencyFormatter.format(data.amount, { code: 'NGN' }),
      customer: `${data?.user?.lastName} ${data?.user?.firstName}`,
      customerEmail: data.customerEmail,
      status: data.paymentStatus,
      date: FormatDate(data.createdAt),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: 'Transaction Id (filterable)',
        prop: 'reference',
        sortable: true,
        filterable: true,
      },

      { title: 'Amount', prop: 'amount' },

      {
        title: 'Customer (filterable)',
        prop: 'customer',
        sortable: true,
        filterable: true,
      },

      {
        title: 'Customer Email (filterable)',
        prop: 'customerEmail',
        sortable: true,
        filterable: true,
      },

      { title: 'Status', prop: 'status' },

      { title: 'Date', prop: 'date' },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import { PayoutTable } from './Table';
import { ConfirmModal } from '../../shared/Modal/ConfirmModal';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { httpGet, httpPost } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [payouts, setPayouts] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState('create');
  let [GroupId, setGroupId] = useState();

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getPayouts();
  }, []);

  const getPayouts = async () => {
    const res = await httpGet('/dispute_payouts');
    if (res.code === 200) {
      console.log('payout', res.data.payouts);
      setPayouts(res.data.payouts);
    }
  };

  const initatePayout = async (id) => {
    console.log('here 123');
    showLoader();
    const res = await httpPost(`/initiate_manual_payout/${id}`);
    if (res.code === 200) {
      swal('Done', res.message, 'success');
      getPayouts();
    }
    hideLoader();
  };

  const settlePayout = async (id) => {
    showLoader();
    const res = await httpPost(`/settled_off_grid/${id}`);
    if (res.code === 200) {
      swal('Done', res.message, 'success');
      getPayouts();
    }
    hideLoader();
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="payouts"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          <PayoutTable
            getPayouts={payouts}
            initatePayout={initatePayout}
            settlePayout={settlePayout}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllGroups={getAllGroups}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { UserCurrentState } from "../../../GlobalState/asyncState";
import { Overview } from "./overview";
import OverviewStat from "./staticticsOverview";
import Statictics from "./statictics";
import "./index.css";
import { httpGet } from "../../../action/http";
import moment from "moment";
import sumUp from "../../../helpers/sumUp";
const Index = (props) => {
  let [isVisible, setIsVisible] = useState({ isVisible: true });
  const [getUserDetails, setUserDetails] = useRecoilState(UserCurrentState);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [monthsGraphData, setmonthsGraphData] = useState({
    jan: [],
    feb: [],
    mar: [],
    apr: [],
    may: [],
    jun: [],
    jul: [],
    aug: [],
    sep: [],
    oct: [],
    nov: [],
    dec: [],
  });

  useEffect(() => {
    getStatistics();
  }, [console.log(monthsGraphData)]);

  const getStatistics = async () => {
    const res = await httpGet("/auth/all_statistics");
    const dashboard_graph = await httpGet("/dashboard_graph");
    if (res.code === 200) {
      console.log("dashboard_graph>>", dashboard_graph.data.paymentHistories);
      let getMonths = dashboard_graph.data.paymentHistories[0].createdAt;
      console.log("alert>>>>", getMonths);
      console.log("months>>>>", moment(getMonths).format("M"));

      console.log(typeof moment(getMonths).format("M"));
      let getJanData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "1";
      });
      let getfebData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "2";
      });
      let getmarData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") === "3";
      });
      let getaprData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "4";
      });
      let getmayData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "5";
      });
      let getJunData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "6";
      });
      let getjulData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "7";
      });
      let getaugData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "8";
      });
      let getsepData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "9";
      });
      let getoctData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "10";
      });
      let getnovData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "11";
      });
      let getdecData = dashboard_graph.data.paymentHistories.filter((data) => {
        return moment(data.createdOn).format("M") == "2";
      });

      setmonthsGraphData({
        ...monthsGraphData,
        jan: getJanData,
        feb: getfebData,
        mar: getmarData,
        apr: getaprData,
        may: getmayData,
        jun: getJunData,
        jul: getjulData,
        aug: getaugData,
        sep: getsepData,
        oct: getoctData,
        nov: getnovData,
        dec: getdecData,
      });
      setStats(res.data);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Layout RouteUserLayout={props.history} page="dashboard">
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <Overview stats={stats} />
            <div id="app">
              {/* <OverviewStat stats={stats} /> */}

              <Statictics stats={monthsGraphData} />
            </div>
            <br />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;

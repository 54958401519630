import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import validator from "validator";
import ImageUploader from "react-images-upload";
import Layout from "../../shared/Layout";
import Input from "../../shared/Input";
import Button from "../../shared/Button";
import "./index.css";
import { httpPost, httpGet, httpPostData } from "../../../action/http";
import removeEmptyString from "../../../utils/removeEmptyString";
import { hideLoader, showLoader } from "../../../utils/loader";

const Create = (props) => {
  const [type, setType] = useState("standard");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [markUpStyle, setMarkUpStyle] = useState("");
  const [markUpPrice, setMarkUpPrice] = useState(null);
  const [overRidesPrice, setOverRidesPrice] = useState(false);
  const [overRidesProductPrice, setOverRidesProductPrice] = useState("");
  const [orderIncrement, setOrderIncrement] = useState(1);
  const [pricingStyle, setPricingStyle] = useState("");
  const [pricePerLitre, setPricePerLitre] = useState(null);
  const [shipping, setShipping] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [shippingFee, setShippingFee] = useState(null);
  const [installationFee, setInstallationFee] = useState("");
  const [depotPrice, setDepotPrice] = useState("");
  const [vendorFee, setVendorFee] = useState("");
  const [image, setImage] = useState("");
  const [minimumOrder, setMinimumOrder] = useState("");
  const [selectedTime, setSelectedTime] = useState("1hr");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [vatFee, SetVateFee] = useState({
    vatToggle: false,
    vatFeePercent: "",
  });
  const [deliveryTime, setDeliveryTime] = useState([
    { time: "1hr" },
    { time: "3hr" },
    { time: "6hr" },
    { time: "9hr" },
    { time: "12hr" },
    { time: "15hr" },
    { time: "18hr" },
    { time: "21hr" },
    { time: "1 day" },
    { time: "2 days" },
    { time: "2 days" },
    { time: "2 days" },
    { time: "5 days" },
  ]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await httpGet("/all_category");
    if (res.code === 200) {
      setCategories(res.data.categories);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validator.isEmpty(title)) {
      swal("Oops!", "Title cannot be empty", "error");
      return;
    }
    if (validator.isEmpty(description)) {
      swal("Oops!", "Description cannot be empty", "error");
      return;
    }

    if (validator.isEmpty(markUpStyle)) {
      swal("Oops!", "Mark Up Style cannot be empty", "error");
      return;
    }
    if (validator.isEmpty(markUpPrice)) {
      swal("Oops!", "Mark Up Price cannot be empty", "error");
      return;
    }

    // if (markUpStyle === 'percentile') {
    //   if (validator.isDecimal(markUpPrice)) {
    //     swal(
    //       'Oops!',
    //       'Mark Up Price must be in decimal for percentile',
    //       'error'
    //     );
    //     return;
    //   }
    // }

    // if (validator.isEmpty(image)) {
    //   swal('error', 'Image is required', 'Error');
    //   return;
    // }

    if (type === "fuel") {
      if (validator.isEmpty(vendorFee)) {
        swal("Oops!", "Vendor Fee is required for fuel type", "error");
        return;
      }

      if (validator.isEmpty(depotPrice)) {
        swal("Oops!", "Depot Price is required for fuel type", "error");
        return;
      }
    }

    let calcPricePerLitre;
    if (type === "fuel") {
      if (markUpStyle === "percentile") {
        calcPricePerLitre =
          parseFloat(vendorFee) +
          parseFloat(depotPrice) +
          (parseFloat(vendorFee) + parseFloat(depotPrice)) *
            parseFloat(parseInt(markUpPrice) / 100);
      } else {
        calcPricePerLitre =
          parseFloat(vendorFee) +
          parseFloat(depotPrice) +
          parseFloat(markUpPrice);
      }
    }

    const data = {
      type,
      title,
      description,
      markUpType: markUpStyle,
      markUpPrice:
        markUpStyle === "percentile"
          ? parseFloat(parseInt(markUpPrice) / 100)
          : parseFloat(markUpPrice),
      overRidesPrice,
      overRidesProductPrice,
      orderIncrement,
      pricingStyle,
      pricePerLitre: type === "fuel" ? calcPricePerLitre : null,
      shipping,
      installation,
      shippingFee: parseFloat(shippingFee),
      installationFee: parseFloat(installationFee),
      depotPrice,
      vendorFee,
      minimumOrder,
      turnAroundTime: selectedTime,
      vatPercentage: vatFee.vatFeePercent,
      vat: vatFee.vatToggle,
    };

    showLoader();
    let formData = new FormData();
    formData.append("file", image);
    let imgUrl;
    const uploadRes = await httpPostData("/auth/image_upload", formData);
    if (uploadRes.code === 201) {
      imgUrl = uploadRes.data.imagePath;
    }
    const addedData = { ...data, image: imgUrl };
    const newData = removeEmptyString(addedData);
    const res = await httpPost("/create_category", newData);
    if (res.code === 201) {
      swal("Done", res.message, "success").then(() =>
        props.history.push("/categories")
      );
    }
    hideLoader();
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImage(pictureFiles[0]);
  };

  return (
    <Layout
      RouteUserLayout={props.history}
      page="category"
      activepage="keepOpenEvents"
    >
      <div style={{ borderRadius: "10px" }} id="category-wraper">
        <div className="center-category-form ">
          <h1>Create Category</h1>
          <form className="category-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Title <span className="impt-i">*</span>
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                name="eventName"
                type="text"
                className="form-control"
                id="title"
                value={title}
                placeholder="Enter Title"
                required={true}
              />
            </div>

            <div className="form-group">
              <label>
                Description <span className="impt-i">*</span>
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                className="form-control"
                id="Presenter"
                placeholder="About Category"
                required
              />
            </div>

            <div className="form-group">
              <label>
                Mark Up Style <span className="impt-i">*</span>
              </label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="mr-3"
                  id="markUpStyle"
                  name="markUpStyle"
                  checked={markUpStyle === "percentile" && true}
                  onClick={(e) =>
                    e.target.checked && setMarkUpStyle("percentile")
                  }
                />
                <label className="form-check-label" for="markUpStyle">
                  Percentile
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input mr-3 ml-5"
                  type="radio"
                  checked={markUpStyle === "amount" && true}
                  id="markUpStyle"
                  name="markUpStyle"
                  onClick={(e) => e.target.checked && setMarkUpStyle("amount")}
                />
                <label className="form-check-label" for="markUpStyle1">
                  Amount
                </label>
              </div>
            </div>

            <div className="form-group">
              <label>
                Mark Up Price <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter the amount to add to the product price in this category e.g(10, 20...)"
                required={true}
                value={markUpPrice}
                onChange={(e) => setMarkUpPrice(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Overide product price</label>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="mr-3"
                  id="overRidePrice"
                  name="overRidePrice"
                  checked={overRidesPrice && true}
                  onClick={(e) =>
                    e.target.checked && setOverRidesPrice(!overRidesPrice)
                  }
                />
                <label
                  className="form-check-label"
                  for="overRidePrice"
                  style={{ fontSize: "8px" }}
                >
                  If checked the value entered here will override the price
                  entered by vendors for the products posted in this category
                </label>
              </div>
            </div>

            <div
              className="form-group"
              style={!overRidesPrice ? { display: "none" } : {}}
            >
              <label>
                Overrides Price <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter new price"
                required={overRidesPrice ? true : false}
                value={overRidesProductPrice}
                onClick={(e) => setOverRidesProductPrice(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Order Increment</label>
              <Input
                className="form-control"
                type="text"
                placeholder="Default to 1 if no number is inserted"
                value={orderIncrement}
                onClick={(e) => setOrderIncrement(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Category Type</label>
              <div className="form-check form-check-inline ml-4">
                <input
                  type="radio"
                  className="mr-3"
                  id="type"
                  name="type"
                  checked={type === "fuel" && true}
                  onClick={(e) => e.target.checked && setType("fuel")}
                />
                <label className="form-check-label" for="type">
                  Fuel
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input mr-3 ml-4"
                  type="radio"
                  checked={type === "standard" && true}
                  id="type"
                  name="type"
                  onClick={(e) => e.target.checked && setType("standard")}
                />
                <label className="form-check-label" for="type1">
                  Standard
                </label>
              </div>
            </div>

            <div
              className="form-group"
              style={type !== "fuel" ? { display: "none" } : {}}
            >
              <label>Pricing Style</label>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="mr-3 ml-3"
                  id="pricingStyle"
                  name="pricingStyle"
                  checked={pricingStyle === "cylinder" && true}
                  onClick={(e) =>
                    e.target.checked && setPricingStyle("cylinder")
                  }
                />
                <label className="form-check-label" for="type1">
                  Cylinder
                </label>
              </div>
              <div className="form-check form-check-inline ml-4 pl-2">
                <input
                  type="radio"
                  className="mr-3"
                  id="pricingStyle"
                  name="pricingStyle"
                  checked={pricingStyle === "litre" && true}
                  onClick={(e) => e.target.checked && setPricingStyle("litre")}
                />
                <label className="form-check-label" for="pricingStyle">
                  Litre
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input mr-3 ml-5"
                  type="radio"
                  checked={pricingStyle === "kg" && true}
                  id="pricingStyle"
                  name="pricingStyle"
                  onClick={(e) => e.target.checked && setPricingStyle("kg")}
                />
                <label className="form-check-label" for="pricingStyle">
                  Kg
                </label>
              </div>
            </div>

            <div
              className="form-group"
              style={type !== "fuel" ? { display: "none" } : {}}
            >
              <div className="row">
                <div className="col-md-4">
                  <label>Deport Price</label>
                  <Input
                    className="ml-3 ml-sm-0"
                    type="text"
                    value={depotPrice}
                    onChange={(e) => setDepotPrice(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <label>Vendor Fee</label>
                  <Input
                    className="ml-3"
                    type="text"
                    value={vendorFee}
                    onChange={(e) => setVendorFee(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <label>Minumum Order</label>
                  <Input
                    className="ml-3"
                    type="text"
                    value={minimumOrder}
                    onChange={(e) => setMinimumOrder(e.target.value)}
                  />
                </div>

                <div className="col-md-4">
                  <label>Turn Around Time</label>

                  <select
                    className="ml-3"
                    type="text"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                  >
                    {deliveryTime.map((x) => {
                      return <option value={x.time}>{x.time}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Parent Category</label>
              <select name="" id="" className="form-control">
                <option value=" " disabled selected>
                  Select
                </option>
                {categories.length
                  ? categories.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    ))
                  : ""}
              </select>
            </div>

            <div className="form-group">
              <div className="form-check form-check-inline mr-0">
                <input
                  type="radio"
                  className="mr-3"
                  id="shipping"
                  name="shipping"
                  checked={shipping}
                  onClick={(e) => e.target.checked && setShipping(!shipping)}
                />
                <label className="form-check-label" for="shipping">
                  Enable shipping Fee
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input mr-3 ml-5"
                  type="radio"
                  checked={installation}
                  id="installation"
                  name="installation"
                  onClick={(e) =>
                    e.target.checked && setInstallation(!installation)
                  }
                />
                <label className="form-check-label" for="installation">
                  Enable Installation Fee
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input mr-3 ml-5"
                  type="radio"
                  checked={vatFee.vatToggle}
                  id="installation"
                  name="installation"
                  onClick={(e) =>
                    e.target.checked &&
                    SetVateFee({ ...vatFee, vatToggle: !vatFee.vatToggle })
                  }
                />
                <label className="form-check-label" for="installation">
                  Enable Vat
                </label>
              </div>
            </div>

            <div
              className="form-group"
              style={!shipping ? { display: "none" } : {}}
            >
              <label>Shipping Fee</label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter fee"
                required={shipping ? true : false}
                value={shippingFee}
                onChange={(e) => setShippingFee(e.target.value)}
              />
            </div>

            <div
              className="form-group"
              style={!installation ? { display: "none" } : {}}
            >
              <label>Installation Fee</label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter fee"
                required={installation ? true : false}
                value={installationFee}
                onClick={(e) => setInstallationFee(e.target.value)}
              />
            </div>

            <div
              className="form-group"
              style={!vatFee.vatToggle ? { display: "none" } : {}}
            >
              <label>Vat Percent</label>
              <Input
                className="form-control"
                type="text"
                placeholder="Input from 0 to 100"
                required={vatFee.vatFee ? true : false}
                value={vatFee.vatFeePercent}
                onChange={(e) =>
                  SetVateFee({ ...vatFee, vatFeePercent: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Upload Image</label>
              <ImageUploader
                withIcon={true}
                withPreview={true}
                buttonText="Select"
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif", "jpeg"]}
                maxFileSize={5242880}
                singleImage={true}
              />
            </div>

            <div className="w-100 text-center mt-5">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Create Category
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Create;

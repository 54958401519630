import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import FormatDate from "../../../helpers/formatDate";
export const ProductTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getProducts.map((data, index) => ({
      name: data?.name,
      companyName: data?.vendor ? data.vendor.companyName : "Admin Fuel Type",
      category: data?.category?.title,
      turnAroundTime: data?.turnAroundTime
        ? data?.turnAroundTime
        : "Time not added",
      description: data?.description,
      status: (
        <span
          style={
            data?.status === "active" ? { color: "green" } : { color: "red" }
          }
        >
          {data?.status.charAt(0).toUpperCase() + data?.status.slice(1)}
        </span>
      ),
      date: FormatDate(data?.createdAt),
      action: (
        <div>
          <button
            className="btn btn-success text-white mr-3"
            onClick={() => props.props.history.push(`/edit_product/${data.id}`)}
          >
            Edit
          </button>
          <button
            className="btn button-primary text-white"
            onClick={() =>
              props.updateStatus(
                data.status === "active" ? "inactive" : "active",
                data.id
              )
            }
          >
            {data.status === "active" ? "Deactivate" : "Activate"}
          </button>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Name (filterable)",
        prop: "name",
        sortable: true,
        filterable: true,
      },

      {
        title: "Company Name",
        prop: "companyName",
        sortable: true,
        filterable: true,
      },

      { title: "Category", prop: "category", sortable: true, filterable: true },
      { title: "Turn Around Time", prop: "turnAroundTime" },
      { title: "Description", prop: "description" },
      { title: "Status", prop: "status" },
      { title: "Date", prop: "date" },

      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

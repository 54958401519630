import React from "react";
import { httpGet, httpPost, httpPatch } from "../action/http";
const UploadImage = async (img) => {
  let avatar = img;
  let formData = new FormData();
  formData.append("file", avatar);
  const res = await httpPost("/auth/image_upload", formData);
  if (res) {
    console.log(res);
    return res.data;
  }
  return res.data;
};
export default UploadImage;

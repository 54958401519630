import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useRecoilState } from 'recoil';
import Layout from '../../shared/Layout';
import { UserCurrentState } from '../../../GlobalState/asyncState';
import { httpGet } from '../../../action/http';
import ReportCards from './reportCards.jsx';
import TransactionReports from './transactionReports';
import UserTransactionReports from './userTransactionReport';
import VendorTransationTable from './vendorTransactionReport';
import TatTransationTable from './tatReport';
import CoverageAreaTable from './CoverageAreaTable';
const Index = (props) => {
  let [isVisible, setIsVisible] = useState({ isVisible: true });
  const [getUserDetails, setUserDetails] = useRecoilState(UserCurrentState);
  const [toggleTable, setToggleTable] = useState('TransactionReports');
  const [Reports, setReports] = useState([]);
  const [transactionReport, settransactionReport] = useState([]);
  const [userTransaction, setuserTransaction] = useState([]);
  const [vendorTransaction, setvendorTransaction] = useState([]);
  const [TatTransaction, setTatTransaction] = useState([]);
  const [coverageArea, setCoverageArea] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllReports();
  }, []);

  const getAllReports = async () => {
    try {
      const report_summary = await httpGet('/report_stats');
      const transaction_report = await httpGet('/transaction_report');
      const user_transaction_report = await httpGet('/user_transaction_report');
      const vendor_transaction_report = await httpGet(
        '/vendor_transaction_report'
      );
      const tat_transaction_report = await httpGet('/tat_report');
      const coverage_area_report = await httpGet('/coverage_area_report');
      // console.log(report_summary, transaction_report);
      if (report_summary.code === 200) {
        setReports(report_summary.data);
        settransactionReport(transaction_report.data?.transactionReport);
        setuserTransaction(user_transaction_report.data?.usersReport);
        setvendorTransaction(vendor_transaction_report.data?.vendorsReport);
        setTatTransaction(tat_transaction_report.data?.tatReport);
        setCoverageArea(coverage_area_report.data?.report);
        // console.log(coverage_area_report.data?.report);
        setLoading(false);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <React.Fragment>
      <Layout RouteUserLayout={props.history} page="reports">
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <ReportCards stats={Reports} />
            <div className="reportsWrapSelect">
              <select onChange={(e) => setToggleTable(e.target.value)}>
                <option value="TransactionReports">Transaction Reports</option>
                <option value="UserTransactionReports">
                  User Transaction Reports
                </option>
                <option value="VendorTransationTable">
                  Vendor Transation Table
                </option>
                <option value="TatTransationTable">TAT Transation Table</option>
                <option value="coverageAreaTable">Coverage Area Table</option>
              </select>
            </div>
            <div className="tableReportsWrap">
              {toggleTable === 'TransactionReports' ? (
                <TransactionReports transactionReport={transactionReport} />
              ) : (
                ''
              )}

              {toggleTable === 'UserTransactionReports' ? (
                <UserTransactionReports transactionReport={userTransaction} />
              ) : (
                ''
              )}

              {toggleTable === 'VendorTransationTable' ? (
                <VendorTransationTable transactionReport={vendorTransaction} />
              ) : (
                ''
              )}

              {toggleTable === 'TatTransationTable' ? (
                <TatTransationTable transactionReport={TatTransaction} />
              ) : (
                ''
              )}

              {toggleTable === 'coverageAreaTable' ? (
                <CoverageAreaTable coverageAreaReport={coverageArea} />
              ) : (
                ''
              )}
            </div>
            <br />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;

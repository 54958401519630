import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import {UserDataProvider} from './context/user.jsx'
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Category from './components/pages/Category';
import VendorMgt from './components/pages/VendorMgt';
import UserMgt from './components/pages/UserMgt';
import EditUser from './components/pages/UserMgt/editUser';
import EditVendor from './components/pages/VendorMgt/editVendor';
import Transaction from './components/pages/Transaction/index';
import ViewTransaction from './components/pages/Transaction/ViewTransaction';
import Product from './components/pages/Product';
import Payout from './components/pages/Payout';
import Dispute from './components/pages/Dispute';
import Messages from './components/pages/Message';
import Subscription from './components/pages/Subscription';
import CreateCategory from './components/pages/Category/Create';
import EditCategory from './components/pages/Category/Edit';
import CreateProduct from './components/pages/Product/Create';
import EditProduct from './components/pages/Product/Edit';
import CreateVendor from './components/pages/VendorMgt/Create';
import CreateUser from './components/pages/UserMgt/Create';
import AgentMgt from './components/pages/ridersMgt/index';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ForgotPassword/ResetPassword';
import Logout from './components/pages/Logout';
import Verify from './components/pages/Verify';
import Reports from './components/pages/Report/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './App.css';
import UserSettings from './components/pages/userProfile/user'
import ViewVendor from './components/pages/userProfile/vendorProfile'
import ConvinetFee from './components/pages/convinentFee/index'
import VatFeeHistory from './components/pages/Vat/index'
function App() {
  return (
    <>
      <NotificationContainer />
    
      <Router>
        <Switch>
        <Route exact path="/" component={Login} />
        <UserDataProvider>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/categories" component={Category} />
          <Route exact path="/subscriptions" component={Subscription} />
          <Route exact path="/create_category" component={CreateCategory} />
          <Route exact path="/edit_category/:id" component={EditCategory} />
          <Route exact path="/create_product" component={CreateProduct} />
          <Route exact path="/edit_product/:id" component={EditProduct} />
          <Route exact path="/create_user" component={CreateUser} />
          <Route exact path="/vendor_management" component={VendorMgt} />
          <Route exact path="/create_vendor" component={CreateVendor} />
          <Route exact path="/user_management" component={UserMgt} />
          <Route exact path="/edit_user/:id" component={EditUser} />
          <Route exact path="/edit_vendor/:id" component={EditVendor} />
          <Route exact path="/agent_management" component={AgentMgt} />
          <Route exact path="/products" component={Product} />
          <Route exact path="/transactions" component={Transaction} />
          <Route exact path="/settings" component={UserSettings} />
          <Route exact path="/view_vendor/:id" component={ViewVendor} />
          <Route exact path="/convenient_fee" component={ConvinetFee} />
          <Route exact path="/vat_fee_history" component={VatFeeHistory} />
          <Route
            exact
            path="/view_transaction/:id"
            component={ViewTransaction}
          />
          <Route exact path="/payouts" component={Payout} />
          <Route exact path="/disputes" component={Dispute} />
          <Route exact path="/messages" component={Messages} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route path="/reset_password/:token" component={ResetPassword} />
          <Route path="/verify" component={Verify} />
          <Route path="/logout" component={Logout} />
          </UserDataProvider>
        </Switch>
      </Router>
     
    </>
  );
}

export default App;

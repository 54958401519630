import React, { useEffect } from "react";
import { Line as LineChart } from "react-chartjs-2";
export default function Statictics({ stats }) {
  useEffect(() => {
    console.log("from GRAPH>>", stats);
  }, [stats]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const chartData = () => {
    console.log("%%%%");
    return {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Sales Over-Time",

          data: [
            stats.jan.length > 0 ? stats.jan[0].total_amount : 0,
            stats.feb.length > 0 ? stats.feb[0].total_amount : 0,
            stats.mar.length > 0 ? stats.mar[0].total_amount : 0,
            stats.apr.length > 0 ? stats.apr[0].total_amount : 0,
            stats.may.length > 0 ? stats.may[0].total_amount : 0,
            ,
            stats.jun.length > 0 ? stats.jun[0].total_amount : 0,
            ,
            stats.jul.length > 0 ? stats.jul[0].total_amount : 0,
            ,
            stats.aug.length > 0 ? stats.aug[0].total_amount : 0,
            stats.sep.length > 0 ? stats.sep[0].total_amount : 0,
            stats.oct.length > 0 ? stats.oct[0].total_amount : 0,
            stats.nov.length > 0 ? stats.nov[0].total_amount : 0,
            stats.dec.length > 0 ? stats.dec[0].total_amount : 0,
          ],
          fill: false,
          backgroundColor: "#1E9682",
          borderColor: "#FDAD00",
          pointStyle: "none",
        },
      ],
    };
  };
  return (
    <div>
      <LineChart data={chartData()} />
    </div>
  );
}

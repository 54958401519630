import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import Button from "../../shared/Button";
import FormatDate from "../../../helpers/formatDate";
export const SubscriptionTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getSubscriptions.map((data, index) => ({
      name: `${data.user.lastName} ${data.user.firstName}`,
      category: data.category.title,
      status: data.status,
      date: FormatDate(data.createdAt),
      action: (
        <div>
          <button
            className="btn button-primary text-white mr-3"
            disabled={data.status === "active" ? true : false}
            style={data.status === "active" ? { opacity: "0.5" } : {}}
            onClick={() => props.approveSubscription("active", data.id)}
          >
            {data.status === "active" ? "Approved" : "Approve"}
          </button>
          <button
            className="btn btn-danger"
            onClick={() => props.approveSubscription("reject", data.id)}
          >
            Reject
          </button>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Vendor Name (filterable)",
        prop: "name",
        sortable: true,
        filterable: true,
      },

      { title: "Category", prop: "category", sortable: true, filterable: true },

      { title: "Status", prop: "status", sortable: true, filterable: true },

      { title: "Date", prop: "date" },

      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

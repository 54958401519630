import React, { useState, useEffect, useContext } from "react";
import "./layout.css";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import avatar from "../../../assets/image/avatar.png";
import { UserDataContext } from "./../../../context/user";

export default function Sidebar(props) {
  const { UserInfo } = useContext(UserDataContext);
  const [toggleSidebarIn, settoggleSidebarIn] = useState({
    dropDownEvent: false,
    dropDownGroup: false,
    dropDownPosts: false,
    dropDownTopics: false,
    dropDownUsers: false,
    dropDownBoard: false,
    userInfo: null,
  });
  const [UserInfoIn, setUserInfoIn] = useState("");

  const getUser = async () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    console.log(setUserInfoIn);
    let d = await UserInfo;
    console.log("user from>>>>", d);
    setUserInfoIn(d);
  };

  const dropDown = (events) => {
    let dropDownEvent = toggleSidebarIn.dropDownEvent;
    let dropDownGroup = toggleSidebarIn.dropDownGroup;
    if (events === "events") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: !toggleSidebarIn.dropDownEvent,
        dropDownGroup: false,
        dropDownPosts: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "groups") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownGroup: !toggleSidebarIn.dropDownGroup,
        dropDownEvent: false,
        dropDownPosts: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "posts") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownPosts: !toggleSidebarIn.dropDownPosts,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownTopics: false,
        dropDownUsers: false,
      });
    }

    if (events === "topics") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownTopics: !toggleSidebarIn.dropDownTopics,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: false,
      });
    }

    if (events === "users") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: !toggleSidebarIn.dropDownUsers,
      });
    }

    if (events === "board") {
      settoggleSidebarIn({
        ...toggleSidebarIn,
        dropDownEvent: false,
        dropDownGroup: false,
        dropDownUsers: false,
        dropDownBoard: !toggleSidebarIn.dropDownBoard,
      });
    }
  };

  useEffect(() => {
    getUser();
    if (props.activepage === "keepOpenEvents") {
      settoggleSidebarIn({
        dropDownEvent: true,
      });
    }

    if (props.activepage === "keepOpenGroup") {
      settoggleSidebarIn({
        dropDownGroup: true,
      });
    }

    if (props.activepage === "keepOpenPosts") {
      settoggleSidebarIn({
        dropDownPosts: true,
      });
    }

    if (props.activepage === "keepOpenTopics") {
      settoggleSidebarIn({
        dropDownTopics: true,
      });
    }

    if (props.activepage === "keepOpenUsers") {
      settoggleSidebarIn({
        dropDownUsers: true,
      });
    }

    if (props.activepage === "allUsers") {
      settoggleSidebarIn({
        dropDownUsers: true,
      });
    }

    if (props.activepage === "reportedUsers") {
      settoggleSidebarIn({
        dropDownUsers: true,
      });
    }

    if (
      props.activepage === "affinity_network/offers" ||
      props.activepage === "affinity_network/partners"
    ) {
      settoggleSidebarIn({
        dropDownAffinity: true,
      });
    }
  }, [UserInfo]);
  return (
    <div>
      <div
        className={`${
          props.sidebarShow === "showSidebar" ? "showSidebar" : "hideSidebar"
        } ${props.SidebarDefault}`}
      >
        <div className={`sidebar`}>
          <Scrollbars
            style={{ width: 224, height: "100vh " }}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <ul className="sidebarList">
              <div className="userDetailsSidebar">
                <div className="userDImageSideWrap">
                  <img
                    src={
                      UserInfoIn === null
                        ? avatar
                        : UserInfoIn?.avatar === null
                        ? avatar
                        : UserInfoIn?.avatar
                    }
                    alt=""
                  />
                </div>
                <div className="usernameWrap">
                  {`${
                    UserInfoIn === null ? "loading..." : UserInfoIn.firstName
                  } ${
                    UserInfoIn === null ? "loading..." : UserInfoIn.lastName
                  }`}
                </div>
                <div style={{ marginLeft: "-30px" }} className="usernameWrap">
                  {`${UserInfoIn === null ? "loading..." : UserInfoIn.email}`}
                </div>
              </div>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/dashboard");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "dashboard" ? "activeClass" : ""}`}
              >
                <i className="fas fa-th"></i>Dashboard
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/user_management")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "user_mgt" ? "activeClass" : ""}`}
              >
                <i className="fa fa-user"></i> User Management
              </li>
              <div
                onClick={() => props.RouteUserLayout.push("/vendor_management")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "vendor_mgt" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-users"></i> Vendor Management
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/agent_management")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "agent_mgt" ? "activeClass" : ""}`}
              >
                <i class="fas fa-people-arrows"></i> Agent Management
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/subscriptions")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "subscription" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-wallet"></i> Subscription
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/vat_fee_history")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "vat_fee_history" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-th"></i> VAT History
              </li>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/convenient_fee");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "convenient_fee" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-credit-card"></i>Convenient Fee
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/categories")}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "categories" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-vote-yea"></i>Categories
              </li>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/products");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "products" ? "activeClass" : ""}`}
              >
                <img src="/assets/image/products.png" /> Products
              </li>
              <div
                onClick={() => {
                  props.RouteUserLayout.push("/messages");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "messages" ? "activeClass" : ""}`}
              >
                <img src="/assets/image/messages.png" /> Messages
              </li>
              <div
                onClick={() => {
                  props.RouteUserLayout.push("/transactions");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${
                  props.page === "transactions" ? "activeClass" : ""
                }`}
              >
                <i className="fas fa-credit-card"></i>Transactions
              </li>

           

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/reports");
                }}
              
                className="hoverActiveRoute"
              ></div>
              <li   className={`${props.page === "reports" ? "activeClass" : ""}`}>
                <i className="far fa-comments"></i>Reports
              </li>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/payouts");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "payouts" ? "activeClass" : ""}`}
              >
                <i className="fa fa-gavel"></i> Disputes & Payouts
              </li>

              <div
                onClick={() => {
                  props.RouteUserLayout.push("/settings");
                }}
                className="hoverActiveRoute"
              ></div>
              <li
                className={`${props.page === "settings" ? "activeClass" : ""}`}
              >
                <i className="fas fa-cogs"></i> Settings
              </li>

              <div
                onClick={() => props.RouteUserLayout.push("/logout")}
                className="hoverActiveRoute"
              ></div>
              <li>
                <i className="fas fa-sign-out-alt"></i>Logout
              </li>
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

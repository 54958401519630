// import React, { useState } from "react";
// import { useRecoilState } from "recoil";
// import { Link } from "react-router-dom";
// import { delet_edit_Handle } from "../../../GlobalState/localState";
// import Table from "../../shared/CustomTable";
// import currencyFormatter from "currency-formatter";
// import dateFormater from "./../../../helpers/formatDate";
// export const TransactionReports = (props) => {
//   let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
//   //   createdAt: "2021-01-06T23:11:23.440Z"
//   //   customer: {firstName: "Moyo", lastName: "Fasinro"}
//   //   order: {id: "1bea658a-77ef-44f5-9503-936893d2ac58", shippingAddress: null, paymentHistory: {amount: "66500"}}
//   //   payout: null
//   //   product: {name: "150 w Solar panel"}
//   //   quantity: 1
//   //   vendor: {firstName: "Nelson", lastName: "Leramah"}
//   const bodyRow = () => {
//     const body = props.transactionReport?.map((data, index) => ({
//       customer: `${data?.customer?.firstName} ${data?.customer?.lastName}`,
//       vendorName: `${data?.vendor?.firstName} ${data?.vendor?.lastName}`,
//       quantity: data.quantity,
//       // amount: currencyFormatter.format(data.amount, { code: "NGN" }),
//       // customer: data.customerEmail,
//       // status: data.paymentStatus,
//       date: dateFormater(data?.createdAt),
//     }));
//     return body;
//   };

//   const header = () => {
//     const header = [
//       {
//         title: "Customer Name",
//         prop: "customer",
//         sortable: true,
//         filterable: true,
//       },

//       {
//         title: "vendor Name",
//         prop: "vendorName",
//         sortable: true,
//         filterable: true,
//       },

//       { title: "Quantity", prop: "quantity" },

//       { title: "Amount", prop: "amount" },

//       {
//         title: "Customer (filterable)",
//         prop: "customer",
//         sortable: true,
//         filterable: true,
//       },

//       { title: "Status", prop: "status" },

//       { title: "Date", prop: "date" },
//     ];
//     return header;
//   };

//   return (
//     <div className="table-responsivee">
//       <Table
//         body={bodyRow}
//         head={header}
//         rowsPerPage={10}
//         rowsPerPageOption={[10, 15, 20, 25]}
//       />
//     </div>
//   );
// };

import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import dateFormater from './../../../helpers/formatDate';
export default function TransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        //         order: {id: "2b1a7470-73ad-4ed2-a600-d97a9ef8dfae", shippingAddress: null, paymentHistory: {amount: "56500"}}
        // id: "2b1a7470-73ad-4ed2-a600-d97a9ef8dfae"
        // paymentHistory: {amount: "56500"}
        // shippingAddress: null
        // payout: {amount: "49500"}
        // amount: "49500"
        columns={[
          { title: 'Product Name', field: 'productName' },
          { title: 'Customer Name', field: 'customer' },
          { title: 'Company Name', field: 'companyName' },
          { title: 'Quantity', field: 'quantity' },
          { title: 'Date', field: 'date' },
          { title: 'Vendor Fee', field: 'vendorFee' },
          { title: 'Admin fee', field: 'Adminfee' },
          { title: 'Sales Price', field: 'SalesPrice' },
        ]}
        data={props.transactionReport.map((data) => {
          return {
            productName: data?.product?.name,
            customer: `${data?.customer?.firstName} ${data?.customer?.lastName}`,
            companyName: `${data?.vendor?.companyName}`,
            quantity: data.quantity,
            vendorFee: `${Number(data?.payout?.amount).toFixed(2)}`,
            Adminfee: `${(
              Number(data?.order?.paymentHistory?.amount) -
              Number(data?.payout?.amount)
            ).toFixed(2)}`,
            SalesPrice: data?.order?.paymentHistory?.amount,
            date: dateFormater(data?.createdAt),
            // status: data.paymentStatus,
            // Check: (
            //   <div classaction="LastLoginCheckbox">
            //     <input id="c1" type="checkbox" />
            //   </div>
            // ),
          };
        })}
        title="Transaction Report"
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useRecoilState } from 'recoil';
import Layout from '../../shared/Layout';
import { UserCurrentState } from '../../../GlobalState/asyncState';
import { httpGet, httpPost } from '../../../action/http';
import './index.css';
import BillerConvinentFeeTable from './table';
import Switch from 'react-switch';
import { hideLoader, showLoader } from './../../../utils/loader';
import { NotificationManager } from 'react-notifications';
import { httpPatch } from './../../../action/http';

const Index = (props) => {
  let [isVisible, setIsVisible] = useState({ isVisible: true });
  const [getUserDetails, setUserDetails] = useRecoilState(UserCurrentState);
  const [toggleTable, setToggleTable] = useState('biller');
  const [convientFeeToggle, setconvientFeeToggle] = useState(false);
  const [convientFeeData, SetconvientFeeData] = useState({});
  const [loading, setLoading] = useState(true);

  const getAllConvientReport = async () => {
    showLoader();
    SetconvientFeeData('');
    setconvientFeeToggle('');
    try {
      const res = await httpGet(`/setting/convenient_fee?type=${toggleTable}`);

      if (res.code === 200) {
        SetconvientFeeData({
          vatFee: res.data?.convenientFee.vatFee,
          convenientFee: res.data?.convenientFee.convenientFee,
          id: res.data?.convenientFee.id,
        });
        setconvientFeeToggle(res?.data?.convenientFee?.status);
        setLoading(false);
        console.log(res.data?.convenientFee);
        hideLoader();
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const handleDropDownSwitch = (data) => {
    setToggleTable(data);
    getAllConvientReport();
  };

  const switchConvientFee = () => {
    setconvientFeeToggle(!convientFeeToggle);
  };

  const handleChange = (e) => {
    SetconvientFeeData({ ...convientFeeData, [e.target.name]: e.target.value });
    console.log(convientFeeData);
  };

  const saveChanges = async () => {
    let data = {
      name: toggleTable,
      vatFee: convientFeeData.vatFee,
      convenientFee: convientFeeData.convenientFee,
      status: convientFeeToggle,
    };
    showLoader();
    try {
      const res = await httpPatch(
        `/setting/convenient_fee/${convientFeeData.id}`,
        data
      );

      if (res.code === 201) {
        setToggleTable(toggleTable);
        getAllConvientReport();
        NotificationManager.success(
          'Convenient Fee Successfully Updated',
          'Yepp!',
          3000
        );
        hideLoader();
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };
  useEffect(() => {
    getAllConvientReport();
  }, [toggleTable]);

  return (
    <React.Fragment>
      <Layout RouteUserLayout={props.history} page="convenient_fee">
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <div className="reportsWrapSelect">
              <select
                onChange={(e) => handleDropDownSwitch(e.target.value)}
                // onClick={(e) => handleDropDownSwitch(e.target.value)}
              >
                <option value="biller">Billers</option>

                <option value="other">Others</option>
              </select>
            </div>
            <div className="setConvinentFeeForm">
              <h1> Update Convenient Fee</h1>

              <div className="convientFeeDivSwitch">
                <Switch
                  checked={convientFeeToggle}
                  onColor="#14274E"
                  uncheckedIcon={convientFeeToggle}
                  checkedIcon={convientFeeToggle}
                  height={20}
                  width={40}
                  handleDiameter={17}
                  onChange={switchConvientFee}
                />
              </div>

              <div className="convientfeeWRapInput">
                <div className="inputCf">
                  <label>Convenient Fee</label>
                  <input
                    disabled={convientFeeToggle == true ? false : true}
                    type="text"
                    value={convientFeeData.convenientFee}
                    name="convenientFee"
                    onChange={handleChange}
                  />
                </div>

                <div className="inputCf">
                  <label>VAT Fee</label>
                  <input
                    disabled={convientFeeToggle == true ? false : true}
                    type="text"
                    value={convientFeeData.vatFee}
                    name="vatFee"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="saveconvientFeeChanges">
                <button onClick={saveChanges}>Save Changes</button>
              </div>
              {/* {toggleTable === "biller" ? (
                <BillerConvinentFeeTable convinentFee={convinentFee} />
              ) : (
                ""
              )} */}
            </div>
            <br />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;

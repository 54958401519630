import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import dateFormater from './../../../helpers/formatDate';

const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

export default function UserTransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        columns={[
          { title: 'User Email', field: 'userEmail' },
          { title: 'Full Name', field: 'customer' },
          { title: 'Total Payment ', field: 'TotalPurchase' },
          { title: 'Last LoggedIn', field: 'date' },
        ]}
        data={props.transactionReport.map((data) => {
          return {
            userEmail: data?.email,
            customer: `${data?.firstName} ${data?.lastName}`,
            TotalPurchase: totalPurchase(data?.paymentHistories),
            date: dateFormater(data?.lastLoggedIn),
          };
        })}
        title="Users Transaction Report"
      />
    </div>
  );
}

import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import dateFormater from '../../../helpers/formatDate';

export default function CoverageAreaReport(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        // email: "robert@rpeltd.com"
        // firstName: "Bob"
        // lastLoggedIn: "2021-03-15T14:16:45.239Z"
        // lastName: "Lee"
        // orderItem: []
        // paymentHistories: []
        columns={[
          { title: 'Lga', field: 'lga' },
          { title: 'State', field: 'state' },
          { title: 'Coverage Area Count', field: 'coverageCount' },
        ]}
        data={props.coverageAreaReport.map((data) => {
          return {
            lga: `${data?.name}`,
            state: `${data?.state?.name}`,
            coverageCount: `${data?.total_vendors}`,
          };
        })}
        title="Coverage Area Report"
      />
    </div>
  );
}

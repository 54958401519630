import React, { Component } from "react";
import "./index.css";

export const Overview = (props) => {
  const {
    successfulTransaction,
    userCount,
    vendorCount,
    productCount,
    totalActiveUsers,
    awaitingProductCount,
    awaitingSubscriptionCount,
    disputeCount,
    transactions,
  } = props.stats;
  return (
    <div>
      <div className="grp-overview">
        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Total Users
          </h1>
          <p>{userCount}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Active Users
          </h1>
          <p>{totalActiveUsers}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i> Total Vendors
          </h1>
          <p>{vendorCount}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-briefcase"></i> Products
          </h1>
          <p>{productCount}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            Successful Transaction
          </h1>

          <p>{successfulTransaction}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            Transactions
          </h1>

          <p>{transactions}</p>
        </div>

        <div
          className={`grp-overview1 ${
            disputeCount > 0 ? "addActiveDahBoardState" : ""
          }`}
        >
          <h1
            className={`${
              disputeCount > 0 ? "addActiveDahBoardStateTextColor" : ""
            }`}
          >
            {" "}
            <i className="fas fa-user-friends"></i>
            Total Disputes
          </h1>

          <p
            className={`${
              disputeCount > 0 ? "addActiveDahBoardStateTextColor" : ""
            }`}
          >
            {disputeCount}
          </p>
        </div>

        <div
          className={`grp-overview1 ${
            awaitingSubscriptionCount > 0 ? "addActiveDahBoardState" : ""
          }`}
        >
          <h1
            className={`${
              awaitingSubscriptionCount > 0
                ? "addActiveDahBoardStateTextColor"
                : ""
            }`}
          >
            {" "}
            <i className="fas fa-user-friends"></i>
            Pending Subscriptions
          </h1>

          <p
            className={`${
              awaitingSubscriptionCount > 0
                ? "addActiveDahBoardStateTextColor"
                : ""
            }`}
          >
            {awaitingSubscriptionCount}
          </p>
        </div>

        <div
          className={`grp-overview1 ${
            awaitingProductCount > 0 ? "addActiveDahBoardState" : ""
          }`}
        >
          <h1
            className={`${
              awaitingProductCount > 0 ? "addActiveDahBoardStateTextColor" : ""
            }`}
          >
            {" "}
            <i className="fas fa-user-friends"></i>
            Pending Products
          </h1>

          <p
            className={`${
              awaitingProductCount > 0 ? "addActiveDahBoardStateTextColor" : ""
            }`}
          >
            {awaitingProductCount}
          </p>
        </div>
      </div>
    </div>
  );
};

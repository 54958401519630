import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { TransactionTable } from "./Table";
import currencyFormatter from "currency-formatter";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet } from "../../../action/http";
import Layout from "../../shared/Layout";
import moment from "moment";
import "./index.css";
import Loader from "react-loader-spinner";
export default function ViewTransaction(props) {
  const [transaction, settransaction] = useState(null);
  useEffect(() => {
    getAlltransaction();
  }, []);

  const getAlltransaction = async () => {
    try {
      const res = await httpGet(
        `/transaction_details/${props.match.params.id}`
      );
      if (res.code === 200) {
        settransaction(res.data.paymentHistory);
      }
    } catch (error) {
      return;
    }
  };

  if (transaction === null) {
    return (
      <div className="row h-100 justify-content-center align-items-center">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <Layout
        RouteUserLayout={props.history}
        page="transactions"
        activepage="keepOpenGroup"
      >
        <div className="postsRoutes ">
          <div className={`postTypes1`}> Billing details</div>
        </div>

        <div>
          <div className="transation-billing-details-wrap">
            <div className="user-info-transation">
              <div className="transation-status">
                <button>{transaction.order.status}</button>
              </div>

              {transaction.user === null ? (
                ""
              ) : (
                <React.Fragment>
                  <img
                    src={
                      transaction.user.avatar === null
                        ? "../profile_pic.png"
                        : transaction.user.avatar
                    }
                    alt=""
                  />
                  <div className="user-transations">
                    <p>{`${transaction.user ? transaction.user.lastName : ""} ${
                      transaction.user ? transaction.user.firstName : ""
                    }`}</p>
                    <p>{`${transaction.user.address}`}</p>
                  </div>

                  <div className="user-transations-email">
                    <h1>Email</h1>
                    <a
                      href={`mailto: ${transaction.user.email}`}
                    >{`${transaction.user.email}`}</a>
                  </div>

                  <div className="user-transations-email">
                    <h1>Phone</h1>
                    <a
                      href={`tel: ${transaction.user.phoneNumber}`}
                    >{`${transaction.user.phoneNumber}`}</a>
                  </div>
                </React.Fragment>
              )}

              {transaction.user === null ? (
                ""
              ) : (
                <div className="user-transations-email">
                  <h1>Payment Via</h1>
                  <p>{`${transaction.bank}`}</p>
                </div>
              )}
            </div>

            <div className="orders-section">
              <hr />
              <div className="transation-orders">
                <div className="orders-container-transation">
                  <div className="transation-product-name">
                    <h1>Product</h1>
                    {transaction.order.orderItem.map((res) => {
                      return (
                        <div>
                          <img
                            src={
                              res.product.image === null
                                ? "Product image not found"
                                : res.product.image
                            }
                            alt=""
                          />

                          <p>{res.product.name}</p>

                          {transaction.order.orderItem.map((res) => {
                            return (
                              <p
                                style={{ marginTop: "-13px" }}
                              >{`Quantity: ${res.quantity}`}</p>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>

                  <div className="transation-product-name">
                    <h1>Amount</h1>
                    {transaction.order.orderItem.map((res) => {
                      return (
                        <div>
                          <p>
                            {` Cost Price:  ${currencyFormatter.format(
                              res.product.costPrice,
                              {
                                code: "NGN",
                              }
                            )}`}
                          </p>
                          <p>
                            {` Selling Price:  ${currencyFormatter.format(
                              res.product.sellingPrice,
                              {
                                code: "NGN",
                              }
                            )}`}
                          </p>

                          <p>
                            {` Shipping Fee:  ${currencyFormatter.format(
                              res.product.shippingFee,
                              {
                                code: "NGN",
                              }
                            )}`}
                          </p>
                        </div>
                      );
                    })}
                  </div>

                  <div className="transation-product-name">
                    <h1>Status</h1>
                    {transaction.order.orderItem.map((res) => {
                      return (
                        <div>
                          <p>
                            <div className="transation-status">
                              <button>{res.product.status}</button>
                            </div>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <hr style={{ marginTop: "2px" }} />
                <div className="order-t-overview">
                  <h1 className="order-ggty">{`Total ${currencyFormatter.format(
                    transaction.amount,
                    {
                      code: "NGN",
                    }
                  )}`}</h1>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>
        <br />
      </Layout>
    );
  }
}

import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import Layout from '../../shared/Layout';
import { TransactionTable } from './Table';
import { ConfirmModal } from '../../shared/Modal/ConfirmModal';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { httpGet } from '../../../action/http';
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [transactions, setTransactions] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState('create');
  let [GroupId, setGroupId] = useState();

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getAllTransactions();
    if (getDeletDetails.reload_state === true) {
      getAllTransactions();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllTransactions = async () => {
    try {
      const res = await httpGet('/transactions');
      if (res.code === 200) {
        setTransactions(res.data.transactions);
      }
    } catch (error) {
      return;
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="transactions"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          <TransactionTable
            getTransactions={transactions || []}
            GetEditDataModals={GetEditDataModals}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllTransactions={getAllTransactions}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;

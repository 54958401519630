import React from "react";
import Icon from "./location.png";
import CategoriesTable from "./agentDriverTable";

export default function CoverageArea({ vendorDrivers }) {
  return (
    <div style={{ marginTop: "25px" }}>
      <CategoriesTable vendorDrivers={vendorDrivers} />
    </div>
  );
}

import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import dateFormater from './../../../helpers/formatDate';

const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

export default function UserTransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        // email: "robert@rpeltd.com"
        // firstName: "Bob"
        // lastLoggedIn: "2021-03-15T14:16:45.239Z"
        // lastName: "Lee"
        // orderItem: []
        // paymentHistories: []
        columns={[
          { title: 'Company name', field: 'Companyname' },
          { title: 'Vendor name', field: 'customer' },
          { title: 'Total Sales ', field: 'TotalPurchase' },
          // { title: "Total payout", field: "TotalPayment" },
          { title: 'Total Product count', field: 'TotalDispute' },
          //  { title: "Total dispute", field: "date" },
          { title: 'Orders accepted', field: 'date' },
          { title: 'Orders rejected', field: 'date' },
        ]}
        data={props.transactionReport.map((data) => {
          return {
            Companyname: data?.companyName,
            customer: `${data?.firstName} ${data?.lastName}`,
            TotalPurchase: `${
              data?.payout?.length === 0
                ? 'No Sales found'
                : `${data?.payout?.length} Sales`
            }`,
            // TotalPayment: data.TotalPayment,
            TotalDispute: `${
              data?.product?.length === 0
                ? 'No Product found'
                : `${data?.product?.length} Products`
            }`,
            // Adminfee:
            //   Number(data?.order?.paymentHistory?.amount) -
            //   Number(data?.payout?.amount),
            // SalesPrice: data?.payout?.amount,
            // date: dateFormater(data?.lastLoggedIn),
            // status: data.paymentStatus,
            // Check: (
            //   <div classaction="LastLoginCheckbox">
            //     <input id="c1" type="checkbox" />
            //   </div>
            // ),
          };
        })}
        title="Vendors Transaction Report"
      />
    </div>
  );
}

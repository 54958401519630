import React, { createContext, useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import "react-responsive-modal/styles.css";
import { NotificationManager } from "react-notifications";
import { hideLoader, showLoader } from "../utils/loader";
import { httpGet, httpPost, httpPatch } from "../action/http";

export const UserDataContext = createContext();

export const UserDataProvider = (props) => {
  const [UserInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const checkToken = localStorage.getItem("token");
    console.log("UserToken>>>", checkToken);
    if (checkToken === null || checkToken === undefined) {
      return console.log("New User");
    }

    showLoader();
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    // alert(parseUserData.id);
    let res = await httpGet(`/auth/get_user/${parseUserData.id}`);
    if (res) {
      if (res.er) {
        if (res.er === "Unauthorized, Your token is invalid or expired") {
        }
        console.log(res.er);
        hideLoader();

        return;
      }
      hideLoader();
      console.log(res.data);
      setUserInfo(res?.data);
      const stringFyUser = JSON.stringify(res.data);
      localStorage.setItem("user", stringFyUser);
    }

    hideLoader();
  };

  return (
    <UserDataContext.Provider
      value={{
        UserInfo,
        setUserInfo,
        getUserProfile,
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

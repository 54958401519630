import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import FormatDate from "../../../helpers/formatDate";
export const UserTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getUsers.map((data, index) => ({
      email: data.email,
      name: (
        <Link to={`edit_user/${data.id}`}>
          {" "}
          {`${data.lastName} ${data.firstName}`}
        </Link>
      ),
      phone: data.phoneNumber,
      lastLogin: FormatDate(data.lastLoggedIn),
      status: data.status,
      action: (
        <div>
          {/* <Link to={`edit_user/${data.id}`}>
            <button
              className="btn btn-success text-white mr-3"
              // onClick={() => props.updateStatus('active', data.id)}
            >
              Edit
            </button>
          </Link> */}
          {/* <button
            className="btn button-primary text-white"
            onClick={() =>
              props.updateStatus(
                data.status === "active" ? "inactive" : "active",
                data.id
              )
            }
          >
            {data.status === "active" ? "Deactivate" : "Activate"}
          </button> */}
          <select
            className="useractionSelect"
            name=""
            onChange={(e) => {
              props.comfirmAction(e.target.value, data.id);
            }}
            id=""
          >
            <option value="">Select Action</option>
            <option value="suspend">Suspend</option>
            <option value="inactivate">Deactivate</option>
            <option value="activate">Activate</option>
          </select>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Email (filterable)",
        prop: "email",
        sortable: true,
        filterable: true,
      },

      { title: "Name", prop: "name", sortable: true, filterable: true },

      { title: "Phone", prop: "phone" },

      {
        title: "Last Login (filterable)",
        prop: "lastLogin",
        sortable: true,
        filterable: true,
      },
      { title: "Status", prop: "status" },
      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

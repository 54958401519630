import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import dateFormater from './../../../helpers/formatDate';

const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

export default function UserTransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        // email: "robert@rpeltd.com"
        // firstName: "Bob"
        // lastLoggedIn: "2021-03-15T14:16:45.239Z"
        // lastName: "Lee"
        // orderItem: []
        // paymentHistories: []
        columns={[
          { title: 'Customer name', field: 'customer' },
          { title: 'Vendor Name', field: 'venorName' },
          { title: 'Product name', field: 'productName' },
          // { title: "Total payout", field: "TotalPayment" },
          { title: 'Order time', field: 'orderTime' },
          //  { title: "Total dispute", field: "date" },
          { title: 'Delivery time', field: 'deliveryTime' },
          //{ title: "Orders rejected", field: "date" },
        ]}
        data={props.transactionReport.map((data) => {
          return {
            customer: `${data?.customer?.firstName} ${data?.customer?.lastName}`,
            venorName: `${data?.vendor?.firstName} ${data?.vendor?.lastName}`,
            productName: `${data?.product?.name}`,
            orderTime: `${dateFormater(data?.startTime)}`,
            deliveryTime: `${dateFormater(data?.deliveryTime)}`,
          };
        })}
        title="TAT Transaction Report"
      />
    </div>
  );
}

import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import Table from '../../shared/CustomTable';
import Button from '../../shared/Button';
import FormatDate from '../../../helpers/formatDate';
export const SubscriptionTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getSubscriptions.map((data, index) => ({
      customerId: data.customerId,
      customerName: `${data?.user?.lastName} ${data?.user?.firstName}`,
      amount: ` ₦${data.amount}`,
      vendAmount: ` ₦${data.vendAmount}`,
      status: data.status,
      reference: data.reference,
      packageSlug: data.packageSlug,
      type: data.type,
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: 'Customer Id',
        prop: 'customerId',
        sortable: true,
        filterable: true,
      },
      {
        title: 'Customer Name',
        prop: 'customerName',
        sortable: true,
        filterable: true,
      },
      { title: 'Amount', prop: 'amount', sortable: true, filterable: true },

      {
        title: 'Vend Amount',
        prop: 'vendAmount',
        sortable: true,
        filterable: true,
      },

      { title: 'Reference', prop: 'reference' },

      { title: 'Package Slug', prop: 'packageSlug' },
      { title: 'Type', prop: 'type' },
      { title: 'Status', prop: 'status', sortable: true, filterable: true },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

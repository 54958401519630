import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useRecoilState } from "recoil";
import { UserToken } from "../../../GlobalState/asyncState";
import OutsideClickHandler from "react-outside-click-handler";
import FormatDate from "../../../helpers/formatDate";
import { baseUrl } from "../../../action/http";
import "./layout.css";

export let socket;

export const Navbar = (props) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState();
  useEffect(() => {
    initiateSocket();
    subscribeToAllNotifications((err, data) => {
      if (err) return;
      console.log("Websocket notifications event received!", data);
      setNotifications(data);
      setNotificationsCount(data.length);
    });
    subscribeToNotification((err, data) => {
      if (err) return;
      console.log("Websocket single notification event received!", data);
      setNotifications((messages) => {
        console.log(messages.find((item) => item.id === data.id));
        if (messages.find((item) => item.id === data.id)) {
          const filteredMssg = messages.filter((item) => item.id !== data.id);
          return [...filteredMssg, data];
        } else {
          return [...messages, data];
        }
      });
    });
    IsUserValidated();

    return () => disconnectSocket();
  }, []);

  const initiateSocket = () => {
    //let token = sessionStorage.getItem('token') || ''
    //console.log(token)
    const ENDPOINT = `${baseUrl}`;
    socket = io(ENDPOINT, {
      transports: ["polling", "websocket"],
    });
    console.log(`Connecting socket...`);
    socket.on("connect", () => {
      console.log("connected", socket.connected);
      socket.emit(`admin_get_notifications`, (msg) => {
        // console.log('all', msg);
      });
    });
  };

  const subscribeToAllNotifications = (cb) => {
    if (!socket) return true;
    socket.on(`admin_get_notifications`, (msg) => {
      // console.log(msg);
      return cb(null, msg);
    });
  };

  const subscribeToNotification = (cb) => {
    if (!socket) return true;
    socket.on(`notification`, (msg) => {
      console.log(msg);
      return cb(null, msg);
    });
  };

  const disconnectSocket = () => {
    console.log("Disconnecting socket...");
    if (socket) socket.disconnect();
  };

  const IsUserValidated = () => {
    const lastUsedToken = localStorage.getItem("token");
    if (
      lastUsedToken === null ||
      lastUsedToken === undefined ||
      lastUsedToken === ""
    ) {
      window.location.replace("/");
    }
  };

  const handleRead = async (id) => {
    console.log("@@", socket.connected);
    socket.emit("readNotification", { notificationId: id, type: "one" });
    await setNotifications((messages) =>
      messages.filter((item) => item.id !== id)
    );
    setNotificationsCount(notifications.length);
  };

  // const markRead = (id, userId, type) => {
  //   console.log("@@");
  //   socket.emit("readNotification", { notificationId: id, userId, type });
  //   // setNotifications((messages) => messages.filter((item) => item.id !== id));
  // };

  return (
    <div>
      <div className="App-header">
        <nav className="navRoutes">
          <span className="appLogo"></span>
          {/* <div className="navItems">
            <span className="linkNav">
              <i className="fas fa-bell"></i>
            </span>
          </div> */}

          <OutsideClickHandler
            onOutsideClick={() => {
              setShowDropDown(false);
            }}
          >
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="btn-group pull-right top-head-dropdown">
                  <span className="button-badge">
                    <i
                      className="fas fa-bell"
                      style={{ color: "white" }}
                      onClick={() => {
                        setNotifyLoading(true);
                        setShowDropDown(!showDropDown);
                        setTimeout(() => {
                          setNotifyLoading(false);
                        }, 3000);
                      }}
                    ></i>
                    <span className="badge-nt">{notificationsCount}</span>
                  </span>

                  <ul
                    className="dropdown-menu dropdown-menu-right"
                    style={showDropDown ? { display: "block" } : {}}
                  >
                    {notifications.length
                      ? notifications.map((item, index) => {
                          let count = 0;
                          if (item.type === "subscribe_category") {
                            count++;
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  handleRead(item.id);
                                  props.RouteUserLayout.push("/subscriptions");
                                }}
                                style={
                                  item.status === "read"
                                    ? { backgroundColor: "#e5e5e5" }
                                    : {}
                                }
                              >
                                <a href="#" className="top-text-block">
                                  <div className="top-text-heading">
                                    {item.message}
                                    {/* You have <b>3 new themes</b> trending */}
                                  </div>
                                  <div className="top-text-light">
                                    {FormatDate(item.createdAt)}
                                  </div>
                                </a>
                              </li>
                            );
                          }
                          if (item.type === "purchase_product") {
                            count++;
                            return (
                              <li key={index}>
                                <a href="#" className="top-text-block">
                                  <div className="top-text-heading">
                                    {item.message}
                                    {/* You have <b>3 new themes</b> trending */}
                                  </div>
                                  <div className="top-text-light">
                                    15 minutes ago
                                  </div>
                                </a>
                              </li>
                            );
                          }
                          // if (!count) {
                          //   return (
                          //     <span className="d-flex justify-content-center">
                          //       No notification found
                          //     </span>
                          //   );
                          // }
                        })
                      : ""}
                    <li>
                      <div
                        className="loader-topbar"
                        style={!notifyLoading ? { display: "none" } : {}}
                      ></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </OutsideClickHandler>

          <div className="toggleEl">
            <i
              onClick={props.handleSideBar}
              className="fa fa-bars togMenu"
              aria-hidden="true"
            ></i>
          </div>
        </nav>
      </div>
    </div>
  );
};

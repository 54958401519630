import React, { ref, useRef } from "react";
import MaleAvatar from "../../../assets/image/male.png";
export default function ProfileHeader({
  userAccount,
  OpenEditProfilePicModalModal,
  vendorReport,
}) {
  // const changeProfileImage = useRef("overlayBoxEditImagewrapHide");
  const showEditImageHandler = (inn) => {
    // if (inn === "in") {
    //   changeProfileImage.current.className = "overlayBoxEditImagewrap";
    // }
    // if (inn === "out") {
    //   changeProfileImage.current.className = "overlayBoxEditImagewrapHide";
    // }
  };
  return (
    <div className="headerWrapdfghkjht">
      <div className="profilePageProfileTitle">
        <span>Vendor Profile</span>
      </div>

      <div className="profileSummarySection">
        <div className="profileImagePBox">
          <div className="overlayBoxEditImage ">
            <div
              // onMouseLeave={() => showEditImageHandler("out")}
              // onMouseEnter={() => showEditImageHandler("in")}
              // ref={changeProfileImage}
              className="overlayBoxEditImagewrapHide"
            >
              <span
                onClick={OpenEditProfilePicModalModal}
                className="overlayBoxEditImagewrapHideText"
              >
                Edit image
              </span>
            </div>
          </div>
          <div
            onMouseLeave={() => showEditImageHandler("out")}
            onMouseEnter={() => showEditImageHandler("in")}
            className="profileImageP"
          >
            <div className="imageRap">
              <img
                onMouseLeave={() => showEditImageHandler("out")}
                onMouseEnter={() => showEditImageHandler("in")}
                src={`${
                  userAccount?.avatar === null
                    ? MaleAvatar
                    : `${userAccount?.avatar}`
                }`}
                alt=""
              />
            </div>

            <span>{userAccount?.companyName}</span>
          </div>
        </div>
        <div className="addMordderToProfileSecBoxs"></div>
        {/* fulfilledOrder: 2 pendingOrders: 23 totalEarnings: 0 totalProducts: 2
        totalTransactions: 18 */}
        <div className="productCoutCardsPOp">
          <div className="Pcard1">
            <span>TOTAL PRODUCT</span>
            <span>{vendorReport?.totalProducts}</span>
          </div>
          <div className="Pcard2">
            <span>TOTAL REVENUE</span>
            <span>N{vendorReport?.totalEarnings}</span>
          </div>
        </div>
        <div className="addMordderToProfileSecBoxs"></div>
        <div className="productCoutCardsPOp">
          <div style={{ backgroundColor: "#000000" }} className="Pcard1">
            <span style={{ color: "#FFFFFF" }}>PENDING ORDERS</span>
            <span style={{ color: "white" }}>
              {vendorReport?.pendingOrders}
            </span>
          </div>
          <div style={{ opacity: 0 }} className="Pcard2">
            <span>PENDING ORDERS</span>
            <span>{vendorReport?.pendingOrders}</span>
          </div>
        </div>
      </div>

      <div className="hrProfilePage"></div>
    </div>
  );
}

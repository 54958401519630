import React from "react";
import Icon from "./location.png";
export default function CoverageArea({ vendorCoverageArea }) {
  return (
    <div style={{ marginTop: "25px" }}>
      {vendorCoverageArea?.map((e) => {
        return (
          <div>
            <div className="coverageAreaWrap">
              <img src={Icon} alt="" className="coverageAreaImage" />
              <div className="coverageAreaText">{`${e?.lga?.state?.name}, ${e?.lga?.name}`}</div>
            </div>
            <div className="hrProfilePage"></div>
          </div>
        );
      })}
    </div>
  );
}

import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import Table from '../../shared/CustomTable';

export const PayoutTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const bodyRow = () => {
    const body = props.getPayouts.map((data, index) => ({
      ref: data.recipentCode,
      vendor: `${data.vendor.lastName} ${data.vendor.firstName}`,
      companyName: data.vendor.companyName,
      amount: data.amount,
      accountName: data.vendor.accountName,
      accountNumber: data.vendor.accountNumber,
      bankName: data.vendor.bankName,
      status: data.status,
      date: data.createdAt,
      action: (
        <div>
          <button
            disabled={
              (data.status === 'paid' || data.status === 'queued') && true
            }
            className={
              data.status === 'paid' || data.status === 'queued'
                ? 'btn btn button-primary text-white w-100'
                : 'btn btn button-primary text-white'
            }
            onClick={() => props.initatePayout(data.id)}
          >
            {data.status === 'paid'
              ? 'Paid'
              : data.status === 'queued'
              ? 'Queued'
              : 'Pay'}
          </button>
          <button
            disabled={
              (data.status === 'paid' || data.status === 'queued') && true
            }
            className={
              data.status === 'paid' || data.status === 'queued'
                ? 'd-none'
                : 'btn btn button-primary text-white ml-3'
            }
            onClick={() => props.settlePayout(data.id)}
          >
            Settle
          </button>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: 'Payout Ref (filterable)',
        prop: 'ref',
        sortable: true,
        filterable: true,
      },

      { title: 'Vendor', prop: 'vendor' },
      { title: 'Company Name', prop: 'companyName' },

      { title: 'Amount', prop: 'amount' },
      { title: 'Account Number', prop: 'accountNumber' },
      { title: 'Account Name', prop: 'accountName' },
      { title: 'Bank Name', prop: 'bankName' },
      { title: 'Status', prop: 'status' },
      {
        title: 'Date (filterable)',
        prop: 'date',
        sortable: true,
        filterable: true,
      },

      { title: 'Action', prop: 'action' },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};

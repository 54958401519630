import React, { Component } from "react";

export default function ReportCards(props) {
  const { GrossProfit, netProfit, totalPayout } = props.stats;
  return (
    <div>
      <div className="grp-overview">
        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Gross Income
          </h1>
          <p>{GrossProfit}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Net Income
          </h1>
          <p>{netProfit}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i> Total Payout
          </h1>
          <p>{totalPayout}</p>
        </div>
      </div>
    </div>
  );
}
